.desktop {
  background-image: url("../../../public/assets/Desktop/Images/iceosbackground.jpg");
  aspect-ratio: 16/9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  padding-bottom: 50px;
}
