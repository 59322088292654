.taskDock {
  position: absolute;
  bottom: 0;
  left: 120px;
  display: flex;
  flex-direction: row;
  height: 50px;
  gap: 10px;
}

.taskDockApplication {
  display: flex;
  gap: 10px 15px;
  height: 50px;
  width: auto;
  border-right: 1px solid white;
  align-items: center;
  padding: 0 10px 0 5px;
}

.applicationIcon {
  margin: 0 auto;
  height: 30px;
  width: 30px;
}

.applicationText {
  color: #FFFFFF;
  font-weight: bold;
}

.closeButtonTaskDock {
  height: 20px;
  background-color: #e1532f;
  border: 1px solid #FFFFFF;
  color: white;
  font-weight: bold;
}