* {
    box-sizing: border-box;
    overflow: hidden;
    font-family: Tahoma, serif;
}

.hidden {
    visibility: hidden;
    position: absolute;
}

.draggable {
    position: absolute;
}
