.app {
  display: flex;
  flex-direction: column;
  align-items: start ;
  margin: 0 0 10px 10px;
}
.app img{
  width: 50px;
  height: 50px;
}

.app p {
  margin: 0 0 0 10px;
  color: #FFFFFF;
}

.app :hover {
  cursor: pointer;
}

#surfwaveIcon {
  width: 75px;
  height: 75px;
  margin: 0 -3px 0 0;
}

#gifsicon{
  padding: 2px;
}