.startButton {
  height: 47px;
  width: 110px;
  bottom: 1px;
  position: fixed;
  background-color: #1B861D;
  border-top-right-radius: 17px;
  border-bottom-right-radius: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: -1px;
  cursor: pointer;
}

.startButton:hover {
  background-color: #157f16;
  box-shadow: inset 0 10px 0 0 #136d17;
}

.startButtonImage {
  height: 30px;
  width: 30px;
  margin-top: 7px;
  margin-left: -10px;
}

.startButtonText {
  color: #FFFFFF;
  font-weight: bold;
  font-size: 22px;
  font-style: italic;
  margin: 8px 0 0 -10px;
}

.menuDisplay {
  width: 300px;
  height: 320px;
  background-color: #D3E5FB;
  display: none;
  position: fixed;
  bottom: 50px;
  border-radius: 0 10px 0 0;
  border-right: solid #0D8DEA;
  border-width: medium;
  z-index: 1000;
}

.menuSpacer {
  height: 50px;
  background-color: #0D8DEA;
  box-shadow: inset 0px 0px 10px 10px rgba(28, 107, 218, 0.7);
  border-radius: 0 15px 0 0;
  width: 305px;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.menuSpacer2 {
  height: 40px;
  background-color: #0D8DEA;
  box-shadow: inset 0px 0px 10px 10px rgba(28, 107, 218, 0.7);
  display: block;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.menuImage {
  height: 45px;
  width: 45px;
  margin-left: 10px;
  margin-top: 3px;
  background-color: #FFFFFF;
  border: solid #0D8DEA;
  padding: 2px 2px 2px 2px;
}

.userInMenu {
  font-size: 16px;
  z-index: 10;
  font-weight: bolder;
  color: #FFFFFF;
  padding-left: 10px;
}

.menuList {
  display: flex;
  flex-direction: row;
  justify-content: left;
  margin: 0 0 0;
  border-bottom: solid thin #FFFFFF;
  cursor: pointer;
}
.menuList:hover {
  background-color: rgba(10, 104, 172, 0.41);
  box-shadow: inset 0px 0px 15px 0px rgba(15, 79, 169, 0.7);
  color: #FFFFFF;
}

.menuListImage {
  height: 45px;
  width: 45px;
  margin-top: 3px;
  margin-left: -30px;
  background-color: #FFFFFF;
  border: solid #0D8DEA;
  padding: 2px 2px 2px 2px;
}

.menuListText {
  margin-left: 10px;
  font-weight: bold;
}

.menuButtonsContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.logoutButton {
  height: 40px;
  width: 40px;
  margin-top: 1px;
  background-color: #FFFFFF;
  border: solid #0D8DEA;
  padding: 2px 2px 2px 2px;
  border-radius: 7px;
  position: absolute;
  right: 55px;
  cursor: pointer;
}

.logoutText {
  position: absolute;
  right: 10px;
  text-align: center;
  color: #FFFFFF;
  bottom: -5px;
  cursor: pointer;
}
