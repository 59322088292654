.closeButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #e1532f;
  border: 2px solid #FFFFFF;
  color: white;
  font-weight: bold;
  margin-right: 4px;
}