.aboutApplicationText {
  margin: 5px;
}

.aboutApplicationText a{
  text-decoration: none;
  color: #0460f6
}

.aboutApplicationText img{
  background-color: #FFFFFF;
  border: 1px #FFFFFF;
  border-radius: 3px;
}

.aboutApplicationContentContainer {
  height: 100%;
  width: 100%;
  overflow: auto;
  background-color: rgba(254, 157, 141, 0.5);
  background-image: url("data:image/svg+xml,%3Csvg width='52' height='26' viewBox='0 0 52 26' xmlns='http://www.w3.org/2000/svg'%3E%3Cg fill='none' fill-rule='evenodd'%3E%3Cg fill='%23ffffff' fill-opacity='0.33'%3E%3Cpath d='M10 10c0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6h2c0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4 3.314 0 6 2.686 6 6 0 2.21 1.79 4 4 4v2c-3.314 0-6-2.686-6-6 0-2.21-1.79-4-4-4-3.314 0-6-2.686-6-6zm25.464-1.95l8.486 8.486-1.414 1.414-8.486-8.486 1.414-1.414z' /%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  padding: 0 5px 5px 5px;
}

hr.solid {
  border-top: 2px solid #0460f6;
}

hr.dotted {
  border-top: 1px dotted #0460f6;
}

.tech {
  bottom: 100px;
}